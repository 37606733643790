'use client'

import UsageDisplay from '@/app/components/usage-display'
import ModelSelector from '@/app/components/model-selector'
import { Model } from '@/app/api'
import { useChatContext } from '@/context/chat-context'
import { SidebarMobile } from '@/app/components/sidebar-mobile'

export default function ChatHeader({
  allModels,
  usageRenewTimestamp,
}: {
  allModels: Model[]
  usageRenewTimestamp: string | null
}) {
  const { usage } = useChatContext()
  const usagePercentage = usage.percentage ?? 0

  return (
    <div className="flex w-full flex-col items-center justify-between lg:flex-row">
      <div className="flex w-full">
        <SidebarMobile />
        <UsageDisplay
          percentage={usagePercentage}
          renewTimestamp={usageRenewTimestamp}
        />
      </div>
      <ModelSelector
        allModels={allModels}
        className="order-1 w-full lg:order-2"
      />
    </div>
  )
}
