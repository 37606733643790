import { Fragment } from 'react'

export const EFundamentalsDisclaimer = () => (
  <Fragment>
    <div className="flex flex-col items-center gap-8 md:flex-row md:items-start">
      <div className="md:w-82 order-1 flex-none md:order-2">
        <iframe
          style={{ borderRadius: '1rem' }}
          id="kmsembed-0_36zdz5yh"
          width="400"
          height="225"
          src="https://api.cast.switch.ch/p/106/embedPlaykitJs/uiconf_id/23449004/partner_id/106?iframeembed=true&playerId=kaltura_player&entry_id=0_36zdz5yh"
          // src="https://uzh.mediaspace.cast.switch.ch/embed/secure/iframe/entryId/0_36zdz5yh/uiConfId/23449004/st/0"
          className="kmsembed"
          allowFullScreen
          allow="autoplay *; fullscreen *; encrypted-media *"
          referrerPolicy="no-referrer-when-downgrade"
          sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
          title="eFundamentals Chatbot Introduction"
        ></iframe>
        KI-generierter Avatar (Benibot)
      </div>
      <div className="order-2 flex-1 md:order-1">
        <p>
          Wir möchten Dich herzlich zu unserem allerersten Chatbot-Projekt
          (Spitzname "Benibot") begrüssen. Der Chatbot soll Dein{' '}
          <strong>persönlicher Tutor</strong> sein.
        </p>
        <h3 className="my-2 text-lg font-semibold">Nutzung</h3>
        <ul className="mb-4 list-disc space-y-2 pl-5 text-left">
          <li>
            Es ist möglich, die <strong>Chat-Engine</strong> auszuwählen, die
            für die Generierung der Chatbot-Antworten verwendet wird (über die
            Auswahl in der oberen rechten Ecke).
          </li>
          <li>
            Der Chatbot soll <strong>finanzbezogene Fragen</strong> beantworten.
            Bitte vermeide Fragen ausserhalb dieses Rahmens, um die Relevanz zu
            wahren.
          </li>
          <li>
            Bitte gib uns <strong>Feedback</strong> zu den Antworten über die
            Daumen hoch/runter und Kommentarschaltflächen. Dies wird uns enorm
            helfen, den Chatbot für zukünftige Tests und Anwendungsfälle zu
            verbessern.
          </li>
          {/*<li>*/}
          {/*  Zurzeit kann der Gesprächsverlauf nicht gespeichert werden. Kopiere*/}
          {/*  deswegen die Inhalte, auf welche du später zurückgreifen möchtest.*/}
          {/*</li>*/}
        </ul>
      </div>
    </div>
    <div className="mb-4 rounded-lg bg-slate-100 p-4">
      <h3 className="mb-2 text-lg font-semibold">Eigenverantwortung</h3>
      <p>
        Die gegebenen Antworten können mehr oder weniger Informationen
        enthalten, als für das Bestehen der Kurse erforderlich sind und die
        Chatbot-Antworten allein sind daher nicht prüfungsrelevant (nur die
        zugrundeliegenden Inhalte). Während wir uns bemühen, durch den
        KI-Chatbot genaue Informationen bereitzustellen, garantieren wir nicht
        die Richtigkeit, Vollständigkeit oder Aktualität der bereitgestellten
        Informationen.
      </p>
    </div>
    <div className="rounded-lg bg-slate-100 p-4">
      <h3 className="mb-2 text-lg font-semibold">Datenschutz</h3>
      <p className="mb-4">
        Gib keinerlei persönliche Informationen an den Chatbot weiter. Die von
        Dir gestellten Fragen werden je nach gewählter Chat-Engine an OpenAI
        oder Anthropic gesendet. Gespräche können in anonymisierter Form von
        unserem Team überprüft werden, um die Leistung des Chatbots zu
        verbessern und unsere Kurse zu optimieren.
      </p>
      <p>
        Durch die Interaktion mit dem Chatbot erkennst Du die oben dargelegten
        Bedungungen an und akzeptierst diese. Wenn Du Feedback oder Bedenken
        hast, kontaktiere uns bitte per Email (
        <a href="mailto:finance@execed.uzh.ch">finance@execed.uzh.ch</a>).
      </p>
    </div>
  </Fragment>
)
