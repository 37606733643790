'use client'

import { Model } from '@/app/api'
import { ChangeEvent } from 'react'
import { twMerge } from 'tailwind-merge'
import { useChatContext } from '@/context/chat-context'

export interface ModelSelectorProps {
  allModels: Model[]
  className: string
}
export default function ModelSelector({
  allModels,
  className,
}: ModelSelectorProps) {
  const { chatModel, setChatModel, disabled } = useChatContext()

  const setModelByValue = (modelValue: string) => {
    const selectedModel = allModels.find((model) => model.value === modelValue)
    if (selectedModel) {
      setChatModel(selectedModel)
    }
  }
  const handleModelChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (disabled) return
    setModelByValue(event.target.value)
  }

  return (
    <div
      className={twMerge([
        'flex flex-row items-center justify-end space-x-2',
        className,
      ])}
    >
      <p className="text-sm">Chatbot powered by</p>
      <div className="w-auto">
        <select
          disabled={disabled}
          value={chatModel.value}
          className={twMerge(
            'block w-full rounded-lg bg-white px-4 py-2 text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700',
            disabled && 'pointer-events-none cursor-not-allowed',
          )}
          onChange={handleModelChange}
        >
          {allModels.map((option) => (
            <option
              value={option.value}
              key={option.value}
              title={option.description}
              disabled={disabled && !option.unlimited}
            >
              {option.name} ({option.cost})
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
