'use client'

import { ScrollArea } from '@/app/components/ui/scroll-area'
import ChatHistory from '@/app/components/chat-history'

export default function SidebarDesktop() {
  return (
    <div className="h-screen rounded-r-md border border-gray-100 bg-white">
      <ScrollArea className="h-full px-2 py-2 lg:py-4">
        <ChatHistory />
      </ScrollArea>
    </div>
  )
}
