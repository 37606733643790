import { Progress } from '@/app/components/ui/progress'
import { Coins } from 'lucide-react'
import UsageRenewText from '@/app/components/usage-renew'

export default function UsageDisplay({
  percentage,
  renewTimestamp,
}: {
  percentage: number
  renewTimestamp: string | null
}) {
  return (
    <div className="order-2 flex w-full flex-col items-center space-x-0 md:order-1 md:flex-row md:space-x-2">
      <div className="flex items-center">
        <span className="inline text-sm">
          {1000 - Math.round(percentage * 10)} / 1000
        </span>
        <Coins className="mx-2 h-4 w-4" />
      </div>
      <div className="w-48 flex-row items-center">
        <Progress value={100 - percentage} className="h-2 w-full" />
        {renewTimestamp && <UsageRenewText renewTimestamp={renewTimestamp} />}
      </div>
    </div>
  )
}
